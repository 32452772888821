<template>
  <v-card class="nospace">
    <span class="note-text"
      >*Note - To duplicate existing inquiry, click recreate inquiry on the
      action column below.
    </span>
    <v-card-text class="nospace">
      <v-data-table
        v-if="!workingCountry.includes('PAK')"
        id="customerListTable"
        hide-actions
        :headers="headers"
        :items="items"
        :disable-initial-sort="true"
        :pagination.sync="pagination"
      >
        <template slot="items" slot-scope="props">
          <td>
            {{ props.item.jobId }}
            <activityLogs
              :type="'inquiry'"
              :id="props.item.inquiryId.toString()"
            />
          </td>
          <td>{{ props.item.inquiryId }}</td>
          <td>
            <img
              v-if="props.item.workingCountry == 'PAK'"
              src="../../assets/pakflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'KSA'"
              src="../../assets/ksaflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'UAE'"
              src="../../assets/Uaeflag.png"
              height="20px"
            />
            <img
              v-if="props.item.workingCountry == 'UAE LA'"
              src="../../assets/Uaeflag.png"
              height="20px"
              style="border-radius: 50%"
            />
            <br />{{ props.item.workingCountry || "-" }}
          </td>
          <td>{{ getJobTypeWithCount(props.item.jobType) || "Land" }}</td>
          <td v-if="props.item.userId">
            <b>Company: </b> {{ capitalize(props.item.userId.companyName) }}
            <br />
            <b>Customer: </b> {{ capitalize(props.item.userId.name) }}
            <br />
            {{ props.item.userId.email || "N.A." }}
            <br />
            {{ props.item.userId.phoneNo }}
            <br />
            <span class="executive_bold" v-if="props.item.salesExecutive"
              ><span v-if="props.item.salesExecutive.isBlocked">{{
                props.item.salesExecutive.name
                  ? "Sales Executive - " + "N.A"
                  : ""
              }}</span>
              <span v-else>
                {{
                  props.item.salesExecutive.name
                    ? "Sales Executive - " + props.item.salesExecutive.name
                    : ""
                }}</span
              >
            </span>
          </td>
          <td v-else>NA</td>
          <td v-if="props.item.bookingDate">
            {{ getTime3(props.item.bookingDate) }}
          </td>
          <td v-else>NA</td>
          <td>
            <salesAddressPopup :inquiryId="props.item.inquiryId" />
          </td>
          <td>{{ props.item.numberOfAssignmentsRequired || "N.A" }}</td>
          <td>{{ props.item.invoiceNumber || "-" }}</td>
          <td>
            {{ props.item.quotedPrice }}
            {{ props.item.customerCurrency || "" }} |
            {{ props.item.inquirySource || "N.A." }}
            <!-- {{ props.item.inquirySource || "N.A." }} | <br /><span
              v-if="
                props.item.workingCountry == 'UAE' ||
                props.item.workingCountry == 'KSA'
              "
              >Credit Limit Remain:<span
                v-if="
                  props.item.userId.creditRemaining == 0 ||
                  props.item.userId.creditRemaining < 0
                "
                class="red-text"
                >{{ props.item.userId.creditRemaining }}
                {{ props.item.customerCurrency || "" }}</span
              >
              <span v-else
                >{{ props.item.userId.creditRemaining }}
                {{ props.item.customerCurrency || "" }}</span
              >
            </span> -->

            <br />
            <a v-if="props.item.proof">
              <v-flex v-if="props.item.proof.length">
                <ProofOfDelivery :deliveryProof="props.item.proof" />
              </v-flex>
            </a>
          </td>
          <td v-if="props.item.quoteNeededBy">
            {{ getTime4(props.item.quoteNeededBy) }}
          </td>
          <td v-else>N A</td>

          <td>
            {{ props.item.inquiryStatus }}
            <br />

            <button
              @click="open(props.item, 'Delay', props.index)"
              v-if="
                props.item.inquiryStatus == 'Inquiry Received' ||
                props.item.inquiryStatus == 'Reopen'
              "
              target="_blank"
              :disabled="props.item.inquiryStatus == 'Low Credit Limit'"
              class="track-ass-btn yellow-button"
              title="Mark as Delayed"
            >
              Delayed
            </button>

            <button
              @click="open(props.item, 'Lead lost', props.index)"
              v-if="
                props.item.inquiryStatus != 'Lead lost' &&
                props.item.inquiryStatus != 'Customer Rate Accepted' &&
                props.item.inquiryStatus != 'Low Credit Limit'
              "
              target="_blank"
              :loading="loading"
              :disabled="props.item.inquiryStatus == 'Low Credit Limit'"
              class="track-ass-btn m-t-10 m-l-10 red-button"
              title="Mark as lead lost"
            >
              Lead Lost
            </button>
          </td>
          <td v-if="props.item.jobId">
            <Assignments
              v-permissions="'activity-logs-jobs'"
              :jobId="props.item.jobId"
              :Section="'Sales'"
            />
          </td>
          <td v-else>N.A.</td>

          <td class>
            <button
              @click="check(props.item.inquiryId)"
              v-if="
                props.item.inquiryStatus != 'Customer Rate Accepted' &&
                props.item.inquiryStatus != 'Lead lost' &&
                props.item.inquiryStatus != 'Low Credit Limit'
              "
              target="_blank"
              :disabled="
                props.item.inquiryStatus == 'Low Credit Limit' || loading
              "
              class="track-ass-btn"
              title="Edit Inquiry"
              v-permissions="'edit-enquiry'"
            >
              <v-icon color="red darken-1" flat prepend-icon>create</v-icon>
            </button>

            <button
              @click="open(props.item, 'Customer Rate Accepted', props.index)"
              v-if="
                props.item.inquiryStatus != 'Lead lost' &&
                props.item.inquiryStatus != 'Customer Rate Accepted' &&
                props.item.inquiryStatus != 'Low Credit Limit' &&
                props.item.workingCountry == 'KSA'
              "
              target="_blank"
              :disabled="props.item.inquiryStatus == 'Low Credit Limit'"
              class="track-ass-btn m-t-10 m-l-10 green-button"
              title="Mark as Rate Accepted"
            >
              Customer Rate Accepted
            </button>

            <button
              @click="open(props.item, 'Customer Rate Accepted', props.index)"
              v-if="
                props.item.inquiryStatus != 'Lead lost' &&
                props.item.inquiryStatus != 'Customer Rate Accepted' &&
                props.item.inquiryStatus != 'Low Credit Limit' &&
                props.item.inquiryStatus != 'Reopen' &&
                props.item.workingCountry == 'UAE'
              "
              target="_blank"
              :disabled="props.item.inquiryStatus == 'Low Credit Limit'"
              class="track-ass-btn m-t-10 m-l-10 green-button"
              title="Mark as Rate Accepted"
            >
              Customer Rate Accepted
            </button>
            <button
              @click="open(props.item, 'Customer Rate Accepted', props.index)"
              v-if="
                props.item.inquiryStatus != 'Lead lost' &&
                props.item.inquiryStatus != 'Customer Rate Accepted' &&
                props.item.inquiryStatus != 'Low Credit Limit' &&
                props.item.inquiryStatus != 'Reopen' &&
                props.item.workingCountry == 'UAE LA'
              "
              target="_blank"
              :disabled="props.item.inquiryStatus == 'Low Credit Limit'"
              class="track-ass-btn m-t-10 m-l-10 green-button"
              title="Mark as Rate Accepted"
            >
              Customer Rate Accepted
            </button>

            <button
              v-permissions="'add-enquiry'"
              @click="
                recreateInquiry(props.item.inquiryId, props.item.userId.userId)
              "
              :disabled="loading"
              v-if="props.item.inquiryStatus == 'Customer Rate Accepted'"
              target="_blank"
              class="track-ass-btn m-t-10 m-l-10 orange-button"
              title="Recreate Inquiry"
            >
              Recreate Inquiry
            </button>

            <button
              @click="goToProfile(props.item.jobId)"
              v-if="
                (props.item.jobId &&
                  props.item.inquiryStatus == 'Customer Rate Accepted') ||
                (props.item.jobId &&
                  props.item.inquiryStatus == 'Low Credit Limit')
              "
              target="_blank"
              class="track-ass-btn m-t-10 m-l-10 yellow-button"
              title="Inquiry Details"
            >
              Details
            </button>
          </td>
        </template>
        <v-alert
          slot="no-results"
          :value="isNoSearchResult"
          color="blue"
          icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
      <v-data-table
        id="customerListTable"
        hide-actions
        v-else
        :headers="headersPak"
        :items="items"
        :disable-initial-sort="true"
        :pagination.sync="pagination"
      >
        <template slot="items" slot-scope="props">
          <td>
            {{ props.item.jobId }}
            <activityLogs
              :type="'inquiry'"
              :id="props.item.inquiryId.toString()"
            />
          </td>
          <td>{{ props.item.inquiryId }}</td>
          <td>
            <img
              v-if="props.item.workingCountry == 'PAK'"
              src="../../assets/pakflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'KSA'"
              src="../../assets/ksaflag.png"
              height="20px"
            /><img
              v-if="props.item.workingCountry == 'UAE'"
              src="../../assets/Uaeflag.png"
              height="20px"
            />
            <img
              v-if="props.item.workingCountry == 'UAE LA'"
              src="../../assets/Uaeflag.png"
              height="20px"
              style="border-radius: 50%"
            />
            <br />{{ props.item.workingCountry || "-" }}
          </td>
          <td>{{ getJobTypeWithCount(props.item.jobType) || "Land" }}</td>
          <td v-if="props.item.userId">
            <b>Company: </b>{{ capitalize(props.item.userId.companyName) }}
            <br />
            <b>Customer: </b> {{ capitalize(props.item.userId.name) }}
            <br />
            {{ props.item.userId.email || "N.A." }}
            <br />
            {{ props.item.userId.phoneNo }}
            <br />
            <span class="executive_bold" v-if="props.item.salesExecutive"
              ><span v-if="props.item.salesExecutive.isBlocked">{{
                props.item.salesExecutive.name
                  ? "Sales Executive - " + "N.A"
                  : ""
              }}</span>
              <span v-else>
                {{
                  props.item.salesExecutive.name
                    ? "Sales Executive - " + props.item.salesExecutive.name
                    : ""
                }}</span
              >
            </span>
          </td>
          <td v-else>NA</td>
          <td v-if="props.item.bookingDate">
            {{ getTime3(props.item.bookingDate) }}
          </td>
          <td v-else>NA</td>
          <td>
            <salesAddressPopup :inquiryId="props.item.inquiryId" />
          </td>
          <td>{{ props.item.numberOfAssignmentsRequired || "N.A" }}</td>
          <td>
            {{ props.item.quotedPrice }}
            {{ props.item.customerCurrency || "" }} |
            {{ props.item.inquirySource || "N.A." }}
            <!-- <br /><span
              v-if="
                props.item.workingCountry == 'UAE' ||
                props.item.workingCountry == 'KSA'
              "
              >Credit Limit Remain:<span
                v-if="
                  props.item.userId.creditRemaining == 0 ||
                  props.item.userId.creditRemaining < 0
                "
                class="red-text"
                >{{ props.item.userId.creditRemaining }}
                {{ props.item.customerCurrency || "" }}</span
              >
              <span v-else
                >{{ props.item.userId.creditRemaining }}
                {{ props.item.customerCurrency || "" }}</span
              >
            </span> -->
            <br />
            <a v-if="props.item.proof && props.item.proof.length">
              <ProofOfDelivery :deliveryProof="props.item.proof" />
            </a>
          </td>
          <td v-if="props.item.quoteNeededBy">
            {{ getTime4(props.item.quoteNeededBy) }}
          </td>
          <td v-else>NA</td>
          <td>
            {{ props.item.inquiryStatus }}
            <br />
            <button
              @click="open(props.item, 'Delay', props.index)"
              v-if="
                props.item.inquiryStatus == 'Inquiry Received' ||
                props.item.inquiryStatus == 'Reopen'
              "
              target="_blank"
              :disabled="props.item.inquiryStatus == 'Low Credit Limit'"
              class="track-ass-btn yellow-button"
              title="Mark as Delayed"
            >
              Delayed
            </button>

            <button
              @click="open(props.item, 'Lead lost', props.index)"
              v-if="
                props.item.inquiryStatus != 'Lead lost' &&
                props.item.inquiryStatus != 'Customer Rate Accepted' &&
                props.item.inquiryStatus != 'Low Credit Limit'
              "
              target="_blank"
              :loading="loading"
              :disabled="props.item.inquiryStatus == 'Low Credit Limit'"
              class="track-ass-btn m-t-10 m-l-10 red-button"
              title="Mark as lead lost"
            >
              Lead Lost
            </button>
          </td>

          <td v-if="props.item.jobId">
            <Assignments
              v-permissions="'activity-logs-jobs'"
              :jobId="props.item.jobId"
              :Section="'Sales'"
            />
          </td>
          <td v-else>N.A.</td>

          <td class>
            <button
              @click="check(props.item.inquiryId)"
              v-if="
                props.item.inquiryStatus != 'Customer Rate Accepted' &&
                props.item.inquiryStatus != 'Lead lost' &&
                props.item.inquiryStatus != 'Low Credit Limit'
              "
              target="_blank"
              :disabled="
                props.item.inquiryStatus == 'Low Credit Limit' || loading
              "
              class="track-ass-btn"
              title="Edit Inquiry"
              v-permissions="'edit-enquiry'"
            >
              <v-icon color="red darken-1" flat prepend-icon>create</v-icon>
            </button>

            <button
              @click="open(props.item, 'Customer Rate Accepted', props.index)"
              v-if="
                props.item.inquiryStatus != 'Lead lost' &&
                props.item.inquiryStatus != 'Customer Rate Accepted' &&
                props.item.inquiryStatus != 'Low Credit Limit'
              "
              target="_blank"
              :disabled="props.item.inquiryStatus == 'Low Credit Limit'"
              class="track-ass-btn m-t-10 m-l-10 green-button"
              title="Mark as Rate Accepted"
            >
              Customer Rate Accepted
            </button>

            <button
              v-permissions="'add-enquiry'"
              @click="
                recreateInquiry(props.item.inquiryId, props.item.userId.userId)
              "
              :disabled="loading"
              v-if="props.item.inquiryStatus == 'Customer Rate Accepted'"
              target="_blank"
              class="track-ass-btn m-t-10 m-l-10 orange-button"
              title="Recreate Inquiry"
            >
              Recreate Inquiry
            </button>

            <button
              @click="goToProfile(props.item.jobId)"
              v-if="
                (props.item.jobId &&
                  props.item.inquiryStatus == 'Customer Rate Accepted') ||
                (props.item.jobId &&
                  props.item.inquiryStatus == 'Low Credit Limit')
              "
              target="_blank"
              class="track-ass-btn m-t-10 m-l-10 yellow-button"
              title="Inquiry Details"
            >
              Details
            </button>
          </td>
        </template>
        <v-alert
          slot="no-results"
          :value="isNoSearchResult"
          color="blue"
          icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="listApiUrl"
        :callback="callback"
        :componentKey="componentKey"
        :action="`sales`"
        :payload="payload"
        :type="1"
        v-on:pagination-load="checkLoading"
      />
    </v-card-text>
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <v-snackbar
      :timeout="6000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
    <v-dialog
      v-model="dialog"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">
          {{ message }}
          <v-text-field
            type="text"
            label="Comments"
            required
            v-model="statusComments"
            :value="statusComments"
          />
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            color="primary darken-1"
            :loading="loading"
            text
            @click.native="agree"
            >Yes</v-btn
          >
          <v-btn color="grey" text @click.native="cancel" :disabled="loading"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
      <!------Low-limit-dialog-------->
    </v-dialog>
    <v-dialog
      v-model="dialog1"
      persistent
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="white--text">Confirmation</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4"
          >Are you sure want accept the customer rate as it is less than your
          credit limit?</v-card-text
        >
        <v-card-actions class="pt-0">
          <v-btn
            color="primary darken-1"
            :loading="loading"
            text
            @click.native="agreeCredit()"
            >Yes</v-btn
          >
          <v-btn color="grey" text @click.native="cancel" :disabled="loading"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import moment from "moment";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import activityLogs from "../Common/activityLogs";
import Loading from "vue-loading-overlay";
import PaginationButtons from "@/components/Pagination/Pagination-New";
import SuccessDialog from "@/components/Common/SuccessDialog";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");
import ErrorBox from "@/components/Common/ErrorBox";
import { page } from "vue-analytics";
import { debuglog } from "util";
import pagination from "@/components/Pagination/pagination";
import { StorageKeys } from "../../constants/constants";
import { salesList, salesListPak } from "@/constants/datatable-headers.js";
import { salesSectionApiUrl } from "@/constants/api-urls.js";
import UpdateStatus from "./UpdateStatus";
import salesAddressPopup from "./salesAddressPopup";
import Assignments from "@/components/Procurement/Assignments";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      this.track("Sales Section", {
        email: localStorage.getItem("email"),
        name: localStorage.getItem("user"),
        "Working Country": workingCountry.toString(),
      });
      this.payload = {
        workingCountry: this.workingCountry,
      };
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = val;
      this.$eventBus.$emit("on-load");

      this.getStoredFilters();
    });

    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
      this.currentItem = null;
    });
  },
  components: {
    pagination,
    activityLogs,
    SuccessDialog,
    ProofOfDelivery,
    ErrorBox,
    UpdateStatus,
    Assignments,
    salesAddressPopup,
  },
  props: {
    dashboardData: Function,
    searchText: String,
    searchTextType: String,
    loading: Boolean,
    countryFilter: String,
    convertedInquiry: String,
    reset: Boolean,
    startDateFilter: String,
    jobTypeFilter: String,
    endDateFilter: String,
    executiveFilter: Object,
    inquiryStatus: String,
  },
  watch: {
    pagination(val) {
      if (val["sortBy"] === "source") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "bookingDate";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "bookingDate";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "trucks") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "numberOfAssignmentsRequired";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "numberOfAssignmentsRequired";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      } else if (val["sortBy"] === "quote") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "quotations";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "quotations";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.$eventBus.$emit("on-load");
        }
      }

      if (!val["sortBy"]) {
        delete this.payload["sortType"];
        delete this.payload["sortBy"];
        if (val["descending"] == null) {
          this.componentKey = this.componentKey + 1;
        }
        this.$eventBus.$emit("on-load");
      }
      deep: true;
    },
    searchTextType: function (n, o) {
      if (n && n.trim() != "") {
        this.$set(this.payload, "searchType", n);
        delete this.payload.searchText;
      }
    },
    convertedInquiry: function (n, o) {
      if (n && n.trim() != "") {
        this.$set(this.payload, "isConvertedInquiry", n);
      }
    },

    searchText: function (n, o) {
      if (n.trim() != "") {
        this.$emit("off-load", false);
        this.payload.searchType = this.searchTextType;
        this.$eventBus.$emit("on-load");
        this.$set(this.payload, "searchText", n);
      } else {
        if (this.searchTextType) {
          this.$set(this.payload, "searchType", this.searchTextType);
          delete this.payload.searchText;
          this.componentKey++;
        }
      }
    },

    inquiryStatus: function (n, o) {
      if (n != "" && n) {
        this.$emit("off-load", false);
        this.$set(this.payload, "inquiryStatus", n);
      }
    },

    jobTypeFilter: function (n, o) {
      if (n != "") {
        this.$emit("off-load", false);
        this.jobTypeFilterValue = n;
        this.$set(this.payload, "jobType", n);
      }
    },
    executiveFilter: function (n, o) {
      if (n.salesExecutive && n.salesExecutive.length) {
        this.executiveFilterValue = n.salesExecutive;
        this.$set(this.payload, "salesExecutive", n.salesExecutive);
        this.payload.allSalesExecutive = n.allSalesExecutive;
      } else if (!n.salesExecutive.length) {
        if (this.payload.salesExecutive || this.payload.allSalesExecutive) {
          delete this.payload.salesExecutive;
          delete this.payload.allSalesExecutive;
          this.componentKey++;
        }
      }
    },
    reset: function (n, o) {
      this.$emit("off-load", false);

      if (n) {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    startDateFilter: function (n, o) {
      if (n != "") {
        this.startDateFilterValue = n;
        this.$set(this.payload, "startDate", n);
      } else {
        this.startDateFilterValue = "";
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    endDateFilter: function (n, o) {
      if (n != "") {
        this.endDateFilterValue = n;
        this.$set(this.payload, "endDate", n);
      } else {
        this.endDateFilterValue = "";
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    deep: true,
    immediate: true,
  },
  data() {
    return {
      operation: "",
      listApiUrl: salesSectionApiUrl.inquiryList,
      operatioName: "",
      workingCountry: null,
      title: null,
      dialog1: false,
      messagePending: null,
      message: null,
      dialog: false,
      pagination: {},
      statusComments: "",
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      currentEnquiry: null,
      currentStatus: null,
      currentIndex: null,
      processing: false,
      componentKey: 0,
      executiveFilterValue: "",
      endDateFilterValue: "",
      jobTypeFilterValue: "",
      startDateFilterValue: "",
      searchTextValue: "",
      search: "",
      isNoSearchResult: false,
      payload: {},
      pages: 1,
      totalPages: 0,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,
        dateOfBirth: null,
        error: null,
        missingField: null,
      },
      headers: salesList,
      headersPak: salesListPak,
      documentData: [],
      items: [],
    };
  },
  methods: {
    getStoredFilters() {
      if (localStorage.getItem("payloadSales") !== null) {
        this.payload.workingCountry = this.workingCountry;
      }
      if (!localStorage.getItem("payloadSales")) {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    checkLoading(event) {
      if (event) {
        this.$emit("on-load", true);
      } else {
        // this.$emit("job-load", false);
        this.$emit("off-load", false);
      }
    },
    recreateInquiry(id, userId) {
      // this.loading = true;
      let url = salesSectionApiUrl.checkRecreateInquiry;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: userId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.status === 200) {
            localStorage.setItem(
              "current-enquiry-page-status",
              JSON.stringify(this.payload)
            );
            this.$router.push(`/inquiry/recreate/${id}`);
            //this.loading = false;
          }
        },
        (error) => {
          // this.loading = false;
          this.x.error = error.response.data.message;
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
        }
      );
    },
    async check(id) {
      this.operationName = "edit-enquiry";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        localStorage.setItem(
          "current-enquiry-page-status",
          JSON.stringify(this.payload)
        );
        this.$router.push(`/inquiry/edit/${id}`);
      } else {
        return;
      }
    },
    goToProfile(jobId) {
      this.$eventBus.$emit("view-details", true);
      this.$router.push(`/jobs/${jobId}/view`);
    },
    page(event) {
      this.pages = event;
    },
    getTime3(date) {
      return moment.utc(date).format("ll");
    },
    getTime4(date) {
      return moment(date).format("ll LT");
    },
    getJobTypeWithCount(type) {
      var stringAir = "";
      var stringSea = "";
      var stringLand = "";
      if (type) {
        var count = {};
        var string = "";
        type.forEach(function (i) {
          count[i] = (count[i] || 0) + 1;
        });
        if (count["2"] > 0) {
          stringAir = " Air-" + count["2"];
        }
        if (count["3"] > 0) {
          stringSea = " Sea-" + count["3"];
        }
        if (count["1"] > 0) {
          stringLand = " Land-" + count["1"];
        }
        if (stringAir.length > 0 || stringSea.length > 0) {
          stringLand = stringLand.length > 0 ? stringLand + "," : stringLand;
        }
        if (stringSea.length > 0) {
          stringAir = stringAir.length > 0 ? stringAir + "," : stringAir;
        }
        return stringLand + stringAir + stringSea;
      } else {
        return "Land-1";
      }
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    open(val, type, index) {
      this.currentStatus = type;
      this.currentEnquiry = val;
      this.currentIndex = index;
      this.statusComments = "";
      this.dialog = true;
      this.title = "Change Inquiry's Status";
      this.message = "Click Yes to update inquiry's status";
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      if (this.statusComments.trim()) {
        if (
          this.currentStatus == "Delay" ||
          this.currentStatus == "Lead lost"
        ) {
          this.updateStatus(this.currentEnquiry, this.currentStatus);
        }
        if (this.currentStatus == "Customer Rate Accepted") {
          this.checkStatus(this.currentEnquiry, this.currentStatus);
        }
      } else {
        this.x.error = "Please provide comments before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return false;
      }
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
      this.dialog1 = false;
    },
    agreeCredit() {
      this.resolve(true);
      if (this.statusComments.trim()) {
        this.updateStatus(this.currentEnquiry, this.currentStatus);
      } else {
        this.x.error = "Please provide comments before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return false;
      }
    },
    checkStatus(value, status) {
      this.currentStatus = status;
      this.currentEnquiry = value;
      //  this.loading = true;
      let url = salesSectionApiUrl.checkCreditLimit;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        inquiryId: value._id,
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.status === 200) {
            page("/sales/" + status + "?user=" + localStorage.getItem("user"));
            this.updateStatus(this.currentEnquiry, this.currentStatus);
            this.componentKey += 1;
            this.dialog = false;
            // this.loading = false;
          }
        },
        (error) => {
          // this.loading = false;
          if (error.response.data.statusCode === 400) {
            this.x.error = error.response.data.message;
          }
          if (error.response.data.statusCode === 402) {
            this.x.error = error.response.data.message;
            this.dialog1 = true;
            this.dialog = false;
          } else {
            this.x.error = error.response.data.message;
          }
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
        }
      );
    },
    updateStatus(value, status) {
      //this.loading = true;
      let url = salesSectionApiUrl.changeStatus;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        inquiryId: value._id,
        inquiryStatus: status,
      };
      if (this.statusComments) {
        body.comments = this.statusComments.trim();
      }
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          if (response.status === 200) {
            this.dialog1 = false;
            this.dialog = false;
            this.identify(localStorage.getItem("email"));
            if (status == "Lead lost") {
              this.track("Lead lost", {
                "Inquiry Id": value.inquiryId || "N.A",
                "Company Name/Customer Name":
                  value.userId.companyName ||
                  value.userId.customer ||
                  value.userId.customerEmail ||
                  "N.A",
                "No. of assignmnets":
                  value.numberOfAssignmentsRequired || "N.A",
                "Job Start Date": value.bookingDateTime || "N.A",
                "Working Country": value.workingCountry || "N.A",
                "Selling Price": value.quotedPrice || "N.A",
                Status: status,
              });
            }
            if (status == "Delay") {
              this.track("Delay Inquiry", {
                "Inquiry Id": value.inquiryId || "N.A",
                "Company Name/Customer Name":
                  value.userId.companyName ||
                  value.userId.customer ||
                  value.userId.customerEmail ||
                  "N.A",
                "No. of assignmnets":
                  value.numberOfAssignmentsRequired || "N.A",
                "Job Start Date": value.bookingDateTime || "N.A",
                "Working Country": value.workingCountry || "N.A",
                "Selling Price": value.quotedPrice || "N.A",
              });
            }
            if (status == "Customer Rate Accepted") {
              this.track("Customer Rate Accepted", {
                "Inquiry Id": value.inquiryId || "N.A",
                "Company Name/Customer Name":
                  value.userId.companyName ||
                  value.userId.customer ||
                  value.userId.customerEmail,
                "No. of assignmnets":
                  value.numberOfAssignmentsRequired || "N.A",
                "Job Start Date": value.bookingDateTime || "N.A",
                "Working Country": value.workingCountry || "N.A",
                "Selling Price": value.quotedPrice || "N.A",
              });
            }
            this.componentKey += 1;
            //  this.loading = false;
          }
        },
        (error) => {
          // this.loading = false;
          this.x.error = error.response.data.message;
          setTimeout(() => {
            this.x.error = "";
          }, 3000);
        }
      );
    },
    // paginationPayload(event) {
    //   this.loading = event;
    //   this.payload = event;
    //   localStorage.removeItem("current-enquiry-page-status");
    // },
    openDocument(data) {
      this.getCustomer(data.userId);
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },

    editEnquiry(id) {
      localStorage.setItem(
        "current-enquiry-page-status",
        JSON.stringify(this.payload)
      );
      this.$router.push(`/inquiry/edit/${id}`);
    },

    getTime(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    callback(res) {
      //localStorage.setItem("payloadSales", JSON.stringify(this.payload));
      this.items = [...res.list];
      this.totalPages = Math.ceil(res.totalData / 15);
      this.pagination.rowsPerPage = 15;
      for (var i = 0; i < this.items.length; i++) {
        this.items[i].createdOn = moment(
          String(this.items[i].registrationDate)
        ).format("MM/DD/YYYY hh:mm A");
      }
      this.dashboardData(res);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
#customerListTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#customerListTable
  .theme--light.v-datatable
  thead
  th.column.sortable.active
  .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
.card--raised {
  border: 1px solid orange !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}
.v-card {
  padding: 15px;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.v-dialog {
  max-width: 400px !important;
}
.v-dialog .v-card {
  padding: 0 !important;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
.red-text {
  color: red;

  animation: animate 1.5s linear infinite;
}
</style>
